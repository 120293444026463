<template>
  <div>
    <mt-button class="mb15">
      <el-input class="input" v-model="account" placeholder="账号 / ID" clearable @change="search" @clear="search"></el-input>
      <el-button @click="onUnBindBtnClick(2)" type="primary">企业微信解绑</el-button>
      <el-button @click="onUnBindBtnClick(1)" type="primary">普通微信解绑</el-button>
      <el-button @click="onRefreshBtnClick">刷新</el-button>
    </mt-button>
    <el-table
      ref="userTable"
      :data="userList"
      stripe
      :height="tableHeight"
      :maxHeight="tableHeight"
      @row-click="userRowSelect"
      @selection-change="userRowChange"
      row-class-name="table-row-cursor"
      style="width: 100%;">
      <el-table-column
        header-align="center"
        align="center"
        type="selection"
        width="30">
      </el-table-column>
      <el-table-column
        header-align="left"
        type="index"
        label="#"
        :index="formatterIndex"
        width="50">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="account"
        label="登录账号">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="oaAccount"
        label="OA账号">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="easDBKey"
        label="账套">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="easUserId"
        width="300"
        label="EASID">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="type"
        label="角色">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="企业微信">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.wxworkUserId" type="success">已绑定</el-tag>
          <el-tag v-else type="info">未绑定</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="微信">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.wxwechatOpenId" type="success">已绑定</el-tag>
          <el-tag v-else type="info">未绑定</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="onPageChange"
      layout="->, total, prev, pager, next"
      :page-size="pageSize"
      :current-page="pageIndex"
      :total="userTotal"
      class="pagination">
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
  import MtButton from '../../components/mt-button'
  import {apiGetUserList, apiUnBindUser} from '../../api/qyUser'
  import {tableMixin} from '../../mixins/table'

  export default {
    name: 'QyUser',
    mixins: [tableMixin],
    components: {MtButton},
    data() {
      return {
        userList: [],
        userTotal: 0,
        selectUserList: [],
        account: ''
      }
    },
    created() {
    },
    activated() {
      this._getUserList()
    },
    methods: {
      onUnBindBtnClick(from) {
        if (this.selectUserList.length === 0) {
          this.$message({
            type: 'warning',
            message: '请选择用户'
          })
          return
        }
        this.$confirm('是否确认将选中用户解绑？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let userAccounts = []
          for (let i in this.selectUserList) {
            let item = this.selectUserList[i]
            userAccounts.push(item.account)
          }
          apiUnBindUser(userAccounts, from).then(data => {
            this.$refs['userTable'].clearSelection()
            this._getUserList()
            this.$message({
              type: 'success',
              message: '解绑成功'
            })
          })
        })
      },
      onRefreshBtnClick() {
        this._getUserList()
      },
      // 用户列表行点击事件
      userRowSelect(row, event, column) {
        this.$refs['userTable'].toggleRowSelection(row)
      },
      // 用户多选时的回调
      userRowChange(selection) {
        this.selectUserList = selection
      },
      search() {
        this.pageIndex = 1
        this._getUserList()
      },
      onPageChange(val) {
        this.pageIndex = val
        this._getUserList()
      },
      _getUserList(account = this.account, pageIndex = this.pageIndex) {
        apiGetUserList(account, pageIndex).then(({data}) => {
          this.userList = data.data
          this.userTotal = data.total
        })
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .input
    width 220px
    margin-right 15px
</style>
