/**
 * Created by ZengFanhui on 2021/1/7 13:34
 */

import { doHttp } from '../utils/common'
import {HTTP_TYPE, PAGE_SIZE} from '../utils/constants'

/**
 * 获取用户列表
 * @param type -1:全部
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function apiGetUserList (account = '', pageIndex = 1, pageSize = PAGE_SIZE) {
  let params = {
    account,
    pageIndex,
    pageSize
  }
  return doHttp('/appUser/list.action', HTTP_TYPE.GET, params)
}

/**
 * 解绑
 * @param userIds
 * @return {*}
 */
export function apiUnBindUser (userAccounts, from) {
  let params = {userAccounts, from}

  return doHttp('/appUser/unBindUser.action', HTTP_TYPE.GET, params)
}
